import React from 'react';

const Senguntharmatrimonypp = () => {
  return (
    <div className="privacy-policy-container">
      <h1><center>Privacy Policy</center></h1>

      <h3>Introduction</h3>
      <p>
        Welcome to Sengunthar Matrimony's Privacy Policy. We are committed to protecting your privacy and ensuring you have a positive experience on our website.
      </p>

      <h3>Information We Collect</h3>
      <p>
        We collect personal information, such as:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Date of birth</li>
        <li>Preferences</li>
      </ul>
      <p>
        We may also collect non-personal information such as browser type, IP address, and access times to analyze trends and improve our services.
      </p>

      <h3>How We Use Your Information</h3>
      <p>
        We use your information to:
      </p>
      <ul>
        <li>Match you with potential partners based on your preferences and criteria.</li>
        <li>Personalize your experience on our website and improve our services.</li>
        <li>Communicate with you regarding your account, service updates, and promotional offers.</li>
        <li>Ensure the security and integrity of our platform.</li>
      </ul>

      <h3>Sharing Your Information</h3>
      <p>
        We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described in this Privacy Policy or required by law.
      </p>
      <p>
        We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you.
      </p>

      <h3>Security of Your Information</h3>
      <p>
        We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
      </p>

      <h3>Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to analyze trends, administer the website, track users' movements around the site, and gather demographic information.
      </p>
      <p>
        You can control cookies through your browser settings and opt-out of certain third-party cookies by visiting the respective websites.
      </p>

      <h3>Changes to This Privacy Policy</h3>
      <p>
        We reserve the right to update or change our Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:privacy@senguntharmatrimony.com">privacy@senguntharmatrimony.com</a>.
      </p>
    </div>
  );
};

export default Senguntharmatrimonypp;
