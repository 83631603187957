import React, { useEffect, useRef, useState } from 'react';
import { Navigation } from "./navigation";
import { senguntharmatrimony } from "../css/senguntharmatrimony.css"
import { MdPadding } from 'react-icons/md';
import { FaLessThanEqual } from 'react-icons/fa';


const Senguntharmatrimony = () => {
  const matches = [
    {
      name: "Pavithra",
      age: 24,
      profession: "Software Engineer",
      interests: "Traveling, Music, Cooking",
      image: "/img/img2.jpg"
    },
    {
      name: "Karthick",
      age: 26,
      profession: "Doctor",
      interests: "Reading, Hiking, Yoga",
      image: "/img/img4.jpg"
    },
    {
      name: "Anitha",
      age: 29,
      profession: "Teacher",
      interests: "Sports, Art, Technology",
      image: "/img/img1.jpg"
    },
  ];
  const aboutRef = useRef(null);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const aboutElement = aboutRef.current;

    const handleScroll = () => {
      if (aboutElement && isElementInViewport(aboutElement)) {
        setAnimate(true);
      } else {
        setAnimate(true);
      }
    };


    handleScroll();


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  };


  return (
    <>
      <nav className="navbar">
        <div style={{display:'flex'}}>
        <div className="navbar-container">
          <div class="navbar-logo">
            <a href="#">
              <img src="img/logosm.png" alt="Company Logo">
              </img>
            </a>
          </div>
          <div className="header">
            <a href="/senguntharmatrimony" >SENGUNTHAR<br></br>
              Matrimony</a>
          </div>
        </div>
        <ul className="nav-links">
          <li><a href="#Home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#Gallery">Gallery</a></li>
          <li><a href="#Contacted">Contact</a></li>
        </ul>
        </div>
      </nav>


      <div id='Home' className="landing-screen">
        <div class="overlay1"></div>

        <div className="content center_content1">
          <div className='center_content'>
            <h1>Welcome to Sengunthar Matrimony</h1>
            <p>Find Your Perfect Life Partner</p>
            <button  id="Getstart" className="cta-button" onClick={()=>{window.scrollTo({ top: 1350, behavior: "smooth" })}}>Get Started</button>
          </div>
          <img src="img/front.jpg" alt="Landing Image" className="landing-image" />

        </div>
      </div>

      <div id='about' className={`about-section ${animate ? 'about-animate' : ''}`} ref={aboutRef}>
        <div className="container">
          <div className="about-content">
            <div className="about-text2">
              <h2>About  Sengunthar  Matrimony</h2>
              <p>
                Sengunthar Matrimony is a leading platform dedicated to connecting individuals from the Sengunthar community
                worldwide. Our mission is to facilitate meaningful connections and lifelong relationships based on shared
                cultural values and traditions.
              </p>
              <p>
                At Sengunthar Matrimony, we strive to provide a secure and user-friendly environment for our members to find
                their perfect life partners. With advanced search capabilities and personalized matchmaking services, we aim
                to make the journey of finding love within the Sengunthar community effortless and enjoyable.
              </p>
            </div>
            <div className="about-image">
              <img src="img/aboutsm.jpg" alt="About Our Wedding" />
            </div>
          </div>
        </div>
      </div>


      <div className="landing-container">
        <div className="content">
          <h1 className="animated-title">Welcome to Sengunthar Matrimony</h1>
          <p className="animated-text">
            Discover your perfect match with our trusted matrimonial services.
          </p>

          <div className="matches-container">
            {matches.map((match, index) => (
              <div className="match-card animated-card" key={index}>
                <img src={match.image} alt={match.name} className="match-image" />
                <h3>{match.name}</h3>
                <p>Age: {match.age}</p>
                <p>Profession: {match.profession}</p>
                <p>Interests: {match.interests}</p>
              </div>
            ))}
          </div>

          <a
            href="https://play.google.com/store/apps/details?id=com.example.senguntharmatrimony"
            target="_blank"
            rel="noopener noreferrer"
            className="cta-button"
          >
            Download on the Play Store
          </a>


        </div>
      </div>

      <div id='Gallery' className="gallery-page">
        <header>

          <h1><center>Gallery</center></h1>
          <br></br>
          <br></br>
        </header>
        <section className="card-container">
          <div className="card blue">
            <div className="card-inner">
              <div className="card-front">
                <img src="img/image1.jpg" alt="Profile 1" />
              </div>
              <div className="card-back">
                <div className="card-content">
                  <h3>ID: SMM 1511</h3>
                  <p>Age: 24</p>
                  <p>Location: Salem</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card purple">
            <div className="card-inner">
              <div className="card-front">
                <img src="img/image4.jpg" alt="Profile 2" />
              </div>
              <div className="card-back">
                <div className="card-content">
                  <h3>ID: SMM 1527</h3>
                  <p>Age: 21</p>
                  <p>Location: Bangalore</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card green">
            <div className="card-inner">
              <div className="card-front">
                <img src="img/image2.jpg" alt="Profile 3" />
              </div>
              <div className="card-back">
                <div className="card-content">
                  <h3>ID: SMM 2054</h3>
                  <p>Age: 26</p>
                  <p>Location: Coimbatore</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card purple">
            <div className="card-inner">
              <div className="card-front">
                <img src="img/image5.jpg" alt="Profile 2" />
              </div>
              <div className="card-back">
                <div className="card-content">
                  <h3>ID: SMM 1456</h3>
                  <p>Age: 23</p>
                  <p>Location: Madurai</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card green">
            <div className="card-inner">
              <div className="card-front">
                <img src="img/image3.jpg" alt="Profile 3" />
              </div>
              <div className="card-back">
                <div className="card-content">
                  <h3>ID: SMM 2069</h3>
                  <p>Age: 24</p>
                  <p>Location: Chennai</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card purple">
            <div className="card-inner">
              <div className="card-front">
                <img src="img/image6.jpg" alt="Profile 2" />
              </div>
              <div className="card-back">
                <div className="card-content">
                  <h3>ID: SMM 1527</h3>
                  <p>Age: 21</p>
                  <p>Location: Vilacheri</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      <footer id='contacted' className="footer-container">
        <div className="footer-section contact-section">
          <h3>Contact Us</h3>
          <p>Email: info@senguntharmatrimony.com</p>
          {/* <p>Phone: +123 456 7890</p> */}
          <p>Address: 24/2, Coimbatore ,TamilNadu.</p>
        </div>

        <div className="footer-section links-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="#Home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#Gallery">Gallery</a></li>
            <li><a href="#Contacted">Contact</a></li>
          </ul>
        </div>
{/* 
        <div className="footer-section social-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="#" className="icon-link">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="#" className="icon-link"> <i className="fa fa-instagram"></i></a>
            <a href="#" className="icon-link">
              <i className="fa fa-google"></i>
            </a>

          </div>
        </div> */}

<div className="footer-section">
                    <h3>Testimonials</h3>
                    <h6>"Thanks to Sengunthar Matrimony, I found my perfect match!" - A Happy User</h6>
                </div>


        <div className="footer-section newsletter-section">
          <h3>Newsletter</h3>
          <p>Subscribe to our newsletter for updates</p>
          <form className="form2">
            <input type="email" placeholder="Your email address" />
            <button type="submit">Subscribe</button>
          </form>
        </div>

        <div className="footer-info">
          <p>&copy; 2024 Senguntharmatrimony. All rights reserved |   <a href="/senguntharmatrimonyPrivacyPolicy" class="Privacytxt" >Privacy Policy</a></p>
        </div>
      </footer>
      <div className="footerprivacy">

      </div>

    </>

  );
};

export default Senguntharmatrimony;


