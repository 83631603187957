import React from 'react';
import { Navigation } from "./navigation";

const PrivacyPolicy = () => {
  return (
    <>
      <Navigation />
      <div style={{ paddingTop: '7%', paddingLeft: '3%', paddingBottom: '2%' }}>
        <h2><center>Privacy Policy</center> </h2>

        <h3>1. Introduction</h3>
        <p>Avelator Solutions India Pvt Ltd is committed to protecting the privacy of our users, clients, and other stakeholders. This Privacy Policy outlines how we collect, use, store, and protect personal information.</p>

        <h3>2. Information We Collect</h3>
        <p>We may collect the following types of information:</p>
        <ul>
          <li><strong>Personal Identification Information:</strong> Name, email address, phone number, etc.</li>
          <li><strong>Technical Data:</strong> IP address, browser type, operating system, etc.</li>
          <li><strong>Usage Data:</strong> Information on how you use our software and services.</li>
          <li><strong>Financial Information:</strong> Credit card details, billing information, etc.</li>
          <li><strong>Other Information:</strong> Any other information you provide to us.</li>
        </ul>

        <h3>3. How We Use Your Information</h3>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide and manage our software and services.</li>
          <li>Improve our software and services.</li>
          <li>Communicate with you about our software, updates, and promotions.</li>
          <li>Process transactions and billing.</li>
          <li>Comply with legal obligations.</li>
        </ul>

        <h3>4. Data Sharing and Disclosure</h3>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described in this policy. We may share information with:</p>
        <ul>
          <li><strong>Service Providers:</strong> Third parties who assist us in operating our software, conducting our business, or providing services to you.</li>
          <li><strong>Legal Requirements:</strong> When required by law or to protect our rights, safety, or property.</li>
        </ul>

        <h3>5. Data Security</h3>
        <p>We implement a variety of security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

        <h3>6. Cookies and Tracking Technologies</h3>
        <p>We use cookies and similar technologies to track the activity on our software and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

        <h3>7. Your Rights</h3>
        <p>You have the right to access, correct, or delete your personal information. You can also object to or restrict our use of your personal information.</p>

        <h3>8. Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website or through our software.</p>

        <h3>9. Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <address>
          Avelator Solutions India Pvt Ltd<br />
          201 F, SECOND FLOOR, PALATHURAI ROAD,MADUKKARAI COIMBATORE,TAMILNADU 641105,<br />
          info@avelator.com<br />
          91 90957 19408
        </address>
      </div>
    </>
  );
};

export default PrivacyPolicy;
