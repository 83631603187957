import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Link } from 'react-router-dom'; // Ensure this import is present


const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    // debugger
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });


  const handleSubmit = (e) => {
    try {
      // debugger
      e.preventDefault();
      console.log(name, email, message);


      var templateParams = {
        email: email,
        name: name,
        message: message
      };


      emailjs
        .send("service_skedys7", "template_6i6dxkx", templateParams, "Czs1OvVw4tMV7nkjh")
        .then(
          (result) => {
            debugger
            console.log(result.text);
            clearState();
          },
          (error) => {
            debugger
            console.log(error.text);
          }
        );
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <>
      <div>
        <div id="contact">
        <hr className="lines"></hr>
          <div className="container">
            <div className="col-md-7">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we will
                    get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" validate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          onChange={handleChange}
                          value={name} />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                          value={email} />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                      value={message}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-4 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p className="address_text">
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
                <p className="address_text">
                {props.data ? props.data.address1 : "loading"}
                </p>
                <p className="address_text">
                {props.data ? props.data.address2 : "loading"}
                </p>
                <p className="address_text">
                {props.data ? props.data.address3 : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <a href="mailto:someone@example.com">

                  <p>
                    <span>
                      <i className="fa fa-envelope-o"></i> Email
                    </span>{" "}
                    <span className="text-light">
                      {props.data ? props.data.email : "loading"}
                    </span>
                  </p>
                </a>

              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.linkedin : "/"} target="_blank">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"} target="_blank">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                     <li>
                    <a href={props.data ? props.data.instagram : "/"} target="_blank">
                      {/* <i className="fa fa-instgram"></i> */}
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.google : "/"} target="_self">
                      <i className="fa fa-google"></i>
                    </a>
                  </li>
                  </ul>
                  <div className="text-align">
            <p>
              &copy; 2024 Avelator Solutions. All rights reserved       
            </p>
             {/* <p >
              <Link to="/PrivacyPolicy">Privacy Policy  | </Link>
              <Link to="/senguntharmatrimony">senguntharmatrimony </Link>
              </p> */}
          </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2024 Avelator Solutions. All rights reserved       
            </p>
             <p>
              <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </p>
          </div>
        </div> */}
      </div></>
  );
};
