import React from "react";
// import logo from '../logo.png'
export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="img/logo.png" style={{ height: '60px', padding: '0px 5px 0px', marginTop: '-15px' }} className="navbar-brand page-scroll" href="#page-top" alt="Avelator Solutions India Private Limited Logo"></img>
            {/* <span style={{ display: 'block', marginBottom: '5px' }}>AVELATOR</span>
            <span>SOLUTIONS</span> */}
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>

              <a className="nav_hover" href="/#page-top">
                Home
              </a>
            </li>
            <li>
              <a href="/#about" className="page-scroll nav_hover">
                About
              </a>
            </li>
            <li>
              <a href="/#services" className="page-scroll nav_hover">
                Services
              </a>
            </li>
            <li>
              <a href="/#contact" className="page-scroll nav_hover">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
