import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import PrivacyPolicy from './components/privacypolicy';
import Senguntharmatrimony from './components/senguntharmatrimony'
import "./App.css";
import Senguntharmatrimonypp from "./components/senguntharmatrimonypp";
import { BrowserRouter as Router, Route, Switch, Routes } from "react-router-dom"; // Import from react-router-dom


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          {/* Route for Privacy Policy */}
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

          {/* Default route */}
          <Route path="/" element={
            <>
              <Navigation />
              <Header data={landingPageData.Header} />
              <About data={landingPageData.About} />
              <Services data={landingPageData.Services} />
              <Contact data={landingPageData.Contact} />
            </>
          } />

          {/* Route for Sengunthar Matrimony */}
          <Route path="/senguntharmatrimony" element={<Senguntharmatrimony />} />
          <Route path="/senguntharmatrimonyPrivacyPolicy" element={<Senguntharmatrimonypp />} />
        </Routes>
      </div>
    </Router>
  );
};


export default App;
